<template>
  <div>
    <CHeader />
    <div class="sm:grid grid-cols-2">
      <div class="w-full sm:w-1/2 place-self-end">
        <LeftCover />
      </div>
      <div class="w-full sm:w-3/4 place-self-start">
        <AssignmentResults />
      </div>
    </div>
    <Footer class="mt-2" />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import CHeader from "@/components/CHeader.vue";
import LeftCover from "@/components/LeftSection.vue";
import AssignmentResults from "@/components/AssignmentResults.vue";
export default {
  name: "AssignmentScore",
  components: {
    Footer,
    CHeader,
    AssignmentResults,
    LeftCover,
  },
};
</script>

<style></style>
