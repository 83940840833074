<template>
  <div>
    <CHeader />
    <div
      class="flex grid-cols-1 px-10"
      style="margin: 0 auto; min-height: 90vh; padding-bottom: 50px"
    >
      <div class="w-full mt-12">
        <Results />
      </div>
    </div>
    <Footer class="fix" />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import CHeader from '@/components/CHeader.vue'
import Results from '@/components/ResultsA.vue'
export default {
  name: 'PublicResults',
  components: {
    Footer,
    CHeader,
    Results,
  },
  data() {
    return {
      // lBoard: "",
      // total: "",
    }
  },
  computed: {
    getQuizId() {
      return this.$store.state.pubStartForm.quiz_id
    },
  },
  //created() {
  // this.$upskill.getLeaderBoard(this.getQuizId).then((res) => {
  //   this.lBoard = res.data.data;
  //   this.total = res.data.quiz_score;
  // });
  // this.$store.dispatch("getLeaderBoard", this.getQuizId);
  //},
}
</script>

<style></style>
