<template>
  <div>
    <div>
      <div class="container mx-auto px-4">
        <div class="flex items-center md:justify-between py-4">
          <div class="w-1/4 md:hidden">
            <svg
              class="fill-current text-gray-800 h-8 w-8"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M16.4 9H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1zm0 4H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1zM3.6 7h12.8c.552 0 .6-.447.6-1 0-.553-.048-1-.6-1H3.6c-.552 0-.6.447-.6 1 0 .553.048 1 .6 1z"
              />
            </svg>
          </div>
          <div
            class="
              w-1/2
              md:w-auto
              text-center text-gray-800 text-2xl
              font-medium
            "
          >
            Upskill Quiz WebApp
          </div>
          <div class="w-1/4 md:w-auto md:flex text-right">
            <div>
              <!--              <img class="inline-block h-8 w-8 rounded-full" src="https://ci3.googleusercontent.com/proxy/ttWuEO-UDDDaWLhGwstEC0u8mMDcPfPl57xaqLgBLH8p6vitHipqojMlibEBzLaqWFsD92XyZxsLtZxZUYRq8UAqf_ArvcY=s0-d-e1-ft#http://backend.upskill.com.bd/assets/images/logo.png" alt="">-->
            </div>
            <div class="hidden md:block md:flex md:items-center ml-2">
              <span class="text-gray-800 text-sm mr-1">Upskill</span>
              <div>
                <svg
                  class="fill-current text-gray-800 h-4 w-4 block opacity-50"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden md:block md:border-b">
      <div class="container mx-auto px-4">
        <div class="md:flex">
          <div class="flex -mb-px mr-8" v-for="o in obj" :key="o.link">
            <a
              :href="o.link"
              class="
                no-underline
                text-gray-800
                hover:text-blue-700
                flex
                items-center
                py-4
                border-b border-blue-dark
              "
            >
              {{ o.text }}
            </a>
          </div>
          <!--          <div class="flex -mb-px mr-8">-->
          <!--            <a href="#" class="no-underline text-gray-800 opacity-50 md:text-grey-dark md:opacity-100 flex items-center py-4 border-b border-transparent hover:opacity-100 md:hover:border-grey-dark">-->
          <!--              <svg class="h-6 w-6 fill-current mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18 8H5.5v-.5l11-.88v.88H18V6c0-1.1-.891-1.872-1.979-1.717L5.98 5.717C4.891 5.873 4 6.9 4 8v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2zm-1.5 7.006a1.5 1.5 0 1 1 .001-3.001 1.5 1.5 0 0 1-.001 3.001z" fill-rule="nonzero"/></svg>              Accounts-->
          <!--            </a>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      obj: [
        { link: "View All Quizzes", text: "View All Quizzes" },
        { link: "View All Records", text: "View All Records" },
      ],
    };
  },
  created() {
    if (this.items) {
      this.obj = this.items;
    }
  },
};
</script>
