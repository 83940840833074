<template>
  <div class="font-sans bg-grey-lighter flex flex-col min-h-screen w-full">
    <Menu></Menu>
    <div class="flex-grow container mx-auto sm:px-4 pt-6 pb-8">
      <div
        class="
          bg-white
          border-t border-b
          sm:border-l sm:border-r
          sm:rounded
          shadow
          mb-6
        "
      >
        <div class="border-b px-6">
          <div class="flex justify-between -mb-px">
            <div class="lg:hidden text-blue-dark py-4 text-lg">
              Price Charts
            </div>
            <div class="flex text-sm">
              <div
                type="button"
                class="
                  appearance-none
                  py-4
                  text-grey-dark
                  border-b border-transparent
                  hover:border-grey-dark
                  mr-3
                "
              >
                Stat
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center px-6 lg:hidden">
          <div class="flex-grow flex-no-shrink py-6">
            <div class="text-grey-darker mb-2">
              <span class="text-3xl align-top">CA$</span>
              <span class="text-5xl">21,404</span>
              <span class="text-3xl align-top">.74</span>
            </div>
            <div class="text-green-light text-sm">
              &uarr; CA$12,955.35 (154.16%)
            </div>
          </div>
          <div class="flex-shrink w-32 inline-block relative">
            <select
              class="
                block
                appearance-none
                w-full
                bg-white
                border border-grey-light
                px-4
                py-2
                pr-8
                rounded
              "
            >
              <option>BTC</option>
              <option>ETH</option>
              <option>LTC</option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                pin-y pin-r
                flex
                items-center
                px-2
                text-grey
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
        </div>
        <Featured></Featured>
      </div>
    </div>
    <div class="container mx-auto">
      <DataList></DataList>
    </div>
    <div class="bg-white border-t">
      <div class="container mx-auto px-4">
        <div class="md:flex justify-between items-center text-sm">
          <div
            class="text-center md:text-left py-3 md:py-4 border-b md:border-b-0"
          >
            <a href="#" class="no-underline text-grey-dark mr-4">Home</a>
            <a href="#" class="no-underline text-grey-dark mr-4">Workshops</a>
            <a href="#" class="no-underline text-grey-dark"
              >Terms &amp; Conditions</a
            >
          </div>
          <div class="md:flex md:flex-row-reverse items-center py-4">
            <div class="text-center mb-4 md:mb-0 md:flex">
              <div class="w-48 inline-block relative mb-4 md:mb-0 md:mr-4">
                <select
                  class="
                    leading-tight
                    block
                    appearance-none
                    w-full
                    bg-white
                    border border-grey-light
                    px-3
                    py-2
                    pr-8
                    rounded
                  "
                >
                  <option>English</option>
                </select>
                <div
                  class="
                    pointer-events-none
                    absolute
                    pin-y pin-r
                    flex
                    items-center
                    px-2
                    text-grey
                  "
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <a
                  href="#"
                  class="
                    inline-block
                    leading-tight
                    bg-blue
                    border border-blue-dark
                    hover:bg-blue-dark
                    px-3
                    py-2
                    text-white
                    no-underline
                    rounded
                  "
                  >Need help?</a
                >
              </div>
            </div>
            <div class="text-grey text-center md:mr-4">&copy; 2021 Upskill</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Featured from "../components/Dash/Featured";
import Menu from "../components/Dash/Menu";
import DataList from "../components/Dash/DataListUser";
export default {
  components: { DataList, Menu, Featured },
};
</script>
