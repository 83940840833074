<template>
  <div
    class="
      flex-grow flex flex-col
      bg-white
      border-t border-b
      sm:rounded
      sm:border
      shadow
      overflow-hidden
    "
  >
    <div class="border-b">
      <div class="flex justify-between px-6 -mb-px">
        <h3 class="text-blue-dark py-4 font-normal text-lg">Your Quizzes</h3>
        <div class="flex">
          <button
            type="button"
            class="
              appearance-none
              py-4
              text-blue-dark
              border-b border-blue-dark
              mr-3
            "
          >
            Test
          </button>
          <button
            type="button"
            class="
              appearance-none
              py-4
              text-grey-dark
              border-b border-transparent
              hover:border-grey-dark
            "
          >
            List View
          </button>
        </div>
      </div>
    </div>
    <div
      class="
        flex flex-wrap
        px-6
        py-6
        text-grey-darker
        items-center
        border-b
        -mx-4
      "
    >
      <div
        class="
          bg-white
          border-t border-b
          sm:rounded
          sm:border
          shadow
          px-8
          mx-4
          hover:shadow-md
        "
        v-for="q in items"
        :key="q.quiz_id"
      >
        <div class="border-b">
          <div class="flex justify-between px-6 -mb-px">
            <h3 class="text-blue-dark py-4 font-normal text-lg">
              #{{ q.quiz_subject }}
            </h3>
          </div>
        </div>
        <div>
          <div class="text-center px-6 py-">
            <div class="py-8">
              <div class="mb-4">
                <svg
                  class="inline-block fill-current text-grey h-16 w-16"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M11.933 13.069s7.059-5.094 6.276-10.924a.465.465 0 0 0-.112-.268.436.436 0 0 0-.263-.115C12.137.961 7.16 8.184 7.16 8.184c-4.318-.517-4.004.344-5.974 5.076-.377.902.234 1.213.904.959l2.148-.811 2.59 2.648-.793 2.199c-.248.686.055 1.311.938.926 4.624-2.016 5.466-1.694 4.96-6.112zm1.009-5.916a1.594 1.594 0 0 1 0-2.217 1.509 1.509 0 0 1 2.166 0 1.594 1.594 0 0 1 0 2.217 1.509 1.509 0 0 1-2.166 0z"
                  />
                </svg>
              </div>
              <p class="text-2xl text-grey-darker font-medium mb-4">
                {{ q.quiz_title }}
              </p>
              <p class="text-grey max-w-xs mx-auto mb-6">
                {{ q.time_required }}
              </p>
              <p class="text-grey max-w-xs mx-auto mb-6">
                {{ new Date(q.updated_at).toUTCString() }}
              </p>
              <div>
                <button
                  type="button"
                  class="
                    bg-blue
                    hover:bg-blue-400
                    hover:text-white
                    border border-blue-dark
                    rounded
                    px-6
                    py-4
                  "
                  @click="getQuiz(q.quiz_id)"
                >
                  Start Quiz
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [],
    };
  },
  methods: {
    getQuizzes() {
      this.$upskill.usrGetQuizzesByOrgID("upskill").then((res) => {
        this.items = res.data.data;
        console.log(res.data.data);
      });
    },
    getQuiz(id) {
      this.$store.state.form.quiz_id = id;
      this.$router.push({ path: `/quiz/${id}` });
    },
  },
  created() {
    this.getQuizzes();
  },
};
</script>
