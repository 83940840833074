<template>
  <div class="photo-upload" :class="disabled ? 'disabled' : 'enabled'">
    <div class="uploader" :class="{ hovering: hovering }" ref="uploader">
      <label class="upper">
        <input
          class="file-photo"
          placeholder="Upload CV"
          type="file"
          @change="handleImage"
          @dragenter="hovering = true"
          @dragleave="hovering = false"
          :disabled="disabled"
        />
        <span>
          Upload CV
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />
          </svg>
        </span>
      </label>
    </div>
  </div>
</template>

<style>
.uploader:hover {
  cursor: pointer;
}
.file-photo {
  display: none;
}
.upper {
  margin-top: 50px;
  display: flex;
  flex-direction: column-reverse;
  max-width: 250px;
}
.upper svg {
  max-width: 24px;
  max-height: 20px;
}
.upper span {
  text-align: center;
  font: normal normal bold 18px/24px Spartan;
  letter-spacing: 0px;
  color: #072ac8;
  padding-bottom: 15px;
  border-bottom: 5px solid #ffc600;
}

.file-photo {
  margin-top: 50px;
}
.file-photo input {
}
/* .uploader {
 position: relative;
overflow: hidden;
 width: 300px;
 height: 250px;
  background-color: #f3f3f3;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border: 2px dashed #e8e8e8;
}
.enabled .uploader.hovering {
  background-color: #bbb;
}
.enabled .uploader:hover {
  background-color: skyblue;
}
.upload-instructions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}
.file-photo {
 position: absolute;
  width: 300px;
  height: 400px;
  top: -50px;
  left: 0;
  z-index: 2;
  opacity: 0;
}
.enabled .file-photo {
  cursor: pointer;
} */
/* .uploader img {
  position: absolute;
  width: 100%;
  top: -1px;
  left: -1px;
 z-index: 1;
 border: none;
} */
</style>

<script>
import axios from 'axios'
import SA from 'sweetalert2'
export default {
  props: ['value', 'disabled', 'type'],
  methods: {
    uploadImage(file) {
      //"X-Auth-Secret": localStorage.getItem("ctoken"),
      const formData = new FormData()
      formData.append('file', file)
      formData.append('id', 75)
      // const LINK = "http://localhost:8080"
      const LINK = 'https://service6.upskill.com.bd'
      axios
        .post(`${LINK}/api/upload/assessment`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          this.$emit('uploaded', res.data)
        })
        .catch(() => {
          SA.fire(
            'Only these file types are supported: PDF, DOC, DOCX, TXT and Maximum file size is 4MB. Please contact us if you any queries.'
          )
        })
    },
    handleImage(event) {
      if (this.disabled) {
        return
      }
      let files = event.target.files
      if (files.length === 0) {
        return
      }
      let reader = new FileReader()
      reader.onload = (event) => {
        this.preview = event.target.result
        this.$emit('input', files[0])
        this.uploadImage(files[0])
      }
      reader.readAsDataURL(files[0])
    },
  },
  data() {
    return {
      hovering: false,
      preview: null,
    }
  },
  computed: {
    backgroundImage() {
      let image = this.preview || this.value
      if (!image) {
        return null
      }
      return `url('${image}')`
    },
  },
}
</script>
