<template>
  <div class="hidden lg:flex">
    <div class="w-1/3 text-center py-8" v-for="o in obj" :key="o.header">
      <div class="border-r">
        <div class="text-grey-darker mb-2">
          <span class="text-3xl align-top">{{ o.left }}</span>
          <span class="text-5xl">{{ o.text }}</span>
          <span class="text-3xl align-top">{{ o.right }}</span>
        </div>
        <div class="text-sm uppercase text-grey tracking-wide">
          {{ o.header }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      obj: [
        {header: "Quizzes", text: "10", right: "", left: ""},
        {header: "Submissions", text: "20", right: "", left: ""},
        {header: "Average Score", text: "5", right: "", left: ""}
      ]
    }
  },
  created() {
    if (this.items) {
      this.obj = this.items
    }
  }
}
</script>
