<template>
  <div>
    <div v-show="!bangla" class="text-UBlue font-bold text-lg 2xl:text-2xl">
      <i class="fas fa-medal"></i>
      Score
    </div>
    <div v-show="bangla" class="text-UBlue font-bold text-lg 2xl:text-2xl">
      <i class="fas fa-medal"></i>
      স্কোর
    </div>
    <div
      v-if="score > 0"
      class="text-UGreen-light font-bold text-6xl font-heading"
    >
      {{ percentage }}%
    </div>
    <div v-if="score > 4" v-show="!bangla">
      <div class="font-bold text-lg text-4xl">Congratulations</div>
    </div>
    <div v-if="score > 4" v-show="bangla">
      <div class="font-bold text-lg text-4xl">অভিনন্দন</div>
    </div>
    <div v-show="!bangla" class="text-lg 2xl:text-2xl">
      You got {{ score }} out of {{ totalScore }}!
    </div>
    <div v-show="bangla" class="text-lg 2xl:text-2xl">
      আপনি {{ score }} এ পেয়েছেন {{ totalScore }}!
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      quiz_id: "",
      score: 0,
      totalScore: 0,
    };
  },
  computed: {
    percentage() {
      let p = (this.score / this.totalScore) * 100;
      return Math.trunc(p);
    },
    showReview() {
      return this.$store.state.showReview;
    },
    bangla() {
      return this.$store.state.bn;
    },
  },
  methods: {
    handleReview() {
      this.$store.commit("showReview", true);
    },
    getResults() {
      this.$upskill.usrgetAssignmentResultByID(this.quizID).then((res) => {
        this.totalScore = res.data.data
          .map((element) => element.question_score)
          .reduce((a, b) => a + b, 0);
        this.score = res.data.data
          .map((element) => element.score)
          .reduce((a, b) => a + b, 0);
      });
    },
  },
  created() {
    this.quizID = this.$route.params.id;
    this.getResults();
  },
};
</script>

<style scoped></style>
