<template>
  <div
    class="
      flex-grow flex flex-col
      bg-white
      border-t border-b
      sm:rounded
      sm:border
      shadow
      overflow-hidden
    "
  >
    <div class="border-b">
      <div class="flex justify-between px-6 -mb-px">
        <h3 class="text-blue-dark py-4 font-normal text-lg">Your Quizzes</h3>
        <div class="flex">
          <button
            type="button"
            class="
              appearance-none
              py-4
              text-blue-dark
              border-b border-blue-dark
              mr-3
            "
            @click="dialogs.addQuiz = !dialogs.addQuiz"
          >
            {{ !dialogs.addQuiz ? "Add Quiz" : "Hide Form" }}
          </button>
          <button
            type="button"
            class="
              appearance-none
              py-4
              text-grey-dark
              border-b border-transparent
              hover:border-grey-dark
            "
          >
            List View
          </button>
        </div>
      </div>
    </div>
    <div
      class="
        flex-grow flex
        px-6
        py-6
        text-grey-darker
        items-center
        border-b
        -mx-4
      "
      v-for="q in items"
      :key="q.quiz_id"
    >
      <div class="w-2/5 xl:w-1/4 px-4 flex items-center">
        <span class="text-lg">{{ q.quiz_title }}</span>
      </div>
      <div class="md:flex px-4 items-center">
        Duration: {{ q.time_required }}
      </div>
      <div class="md:flex px-4 items-center">Topic: {{ q.quiz_subject }}</div>
      <div class="flex w-3/5 md:w/12">
        <div class="w-1/2 px-4">
          <button class="text-right text-green-600" @click="editQuiz(q)">
            {{ dialogs.editQuiz ? "Hide" : "Edit/View" }}
          </button>
        </div>
        <div class="w-1/2 px-4">
          <button class="text-right text-grey" @click="deleteQuiz(q)">
            Delete
          </button>
        </div>
      </div>
    </div>
    <div v-if="dialogs.addQuiz">
      <div
        class="
          min-h-screen
          bg-gray-100
          py-6
          flex flex-col
          justify-center
          sm:py-12
        "
      >
        <div class="relative py-3 sm:max-w-xl sm:mx-auto">
          <div
            class="
              relative
              px-4
              py-10
              bg-white
              mx-8
              md:mx-0
              shadow
              rounded-3xl
              sm:p-10
            "
          >
            <div class="max-w-md mx-auto">
              <div class="flex items-center space-x-5">
                <div
                  class="
                    h-14
                    w-14
                    bg-yellow-200
                    rounded-full
                    flex flex-shrink-0
                    justify-center
                    items-center
                    text-yellow-500 text-2xl
                    font-mono
                  "
                >
                  i
                </div>
                <div
                  class="
                    block
                    pl-2
                    font-semibold
                    text-xl
                    self-start
                    text-gray-700
                  "
                >
                  <h2 class="leading-relaxed">Create a Quiz</h2>
                  <p class="text-sm text-gray-500 font-normal leading-relaxed">
                    All fields are mandatory*
                  </p>
                </div>
              </div>
              <div class="divide-y divide-gray-200">
                <div
                  class="
                    py-8
                    text-base
                    leading-6
                    space-y-4
                    text-gray-700
                    sm:text-lg
                    sm:leading-7
                  "
                >
                  <div class="flex flex-col">
                    <label class="leading-loose">Quiz Title</label>
                    <input
                      type="text"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Quiz title"
                      v-model="quizData.title"
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="leading-loose">Category/Topic</label>
                    <input
                      type="text"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Category or topic"
                      v-model="quizData.subject"
                    />
                  </div>
                  <div class="flex items-center space-x-4">
                    <div class="flex flex-col">
                      <label class="leading-loose">Time</label>
                      <div
                        class="
                          relative
                          focus-within:text-gray-600
                          text-gray-400
                        "
                      >
                        <input
                          type="number"
                          class="
                            pr-4
                            pl-10
                            py-2
                            border
                            focus:ring-gray-500
                            focus:border-gray-900
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                            focus:outline-none
                            text-gray-600
                          "
                          v-model="quizData.time"
                        />
                        <div class="absolute left-3 top-2">
                          <!--                          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>-->
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <label class="leading-loose">-</label>
                      <div
                        class="
                          relative
                          focus-within:text-gray-600
                          text-gray-400
                        "
                      >
                        Seconds
                        <div class="absolute left-3 top-2"></div>
                      </div>
                    </div>
                  </div>
                  <!--                  <div class="flex flex-col">-->
                  <!--                    <label class="leading-loose">Other</label>-->
                  <!--                    <input type="text" class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Optional">-->
                  <!--                  </div>-->
                </div>
                <div class="pt-4 flex items-center space-x-4">
                  <button
                    class="
                      flex
                      justify-center
                      items-center
                      w-full
                      text-gray-900
                      px-4
                      py-3
                      rounded-md
                      focus:outline-none
                    "
                    @click="dialogs.addQuiz = false"
                  >
                    <svg
                      class="w-6 h-6 mr-3"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                    Cancel
                  </button>
                  <button
                    class="
                      bg-blue-500
                      flex
                      justify-center
                      items-center
                      w-full
                      text-white
                      px-4
                      py-3
                      rounded-md
                      focus:outline-none
                    "
                    @click="addQuiz"
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dialogs.editQuiz">
      <div class="flex justify-between px-6 -mb-px border-b">
        <h3 class="text-blue-dark py-4 font-normal text-lg">Questions</h3>
        <div class="flex">
          <button
            type="button"
            class="
              appearance-none
              py-4
              text-blue-dark
              border-b border-blue-dark
              mr-3
            "
            @click="dialogs.addQues = !dialogs.addQues"
          >
            {{ !dialogs.addQues ? "Add Question" : "Hide Form" }}
          </button>
        </div>
      </div>
      <div
        class="
          flex-grow flex
          px-6
          py-6
          text-grey-darker
          items-center
          border-b
          -mx-4
        "
        v-for="q in questions"
        :key="q.question_id"
      >
        <div class="w-2/5 xl:w-1/4 px-4 flex items-center">
          <span class="text-lg">{{ q.question_subject }}</span>
        </div>
        <div class="md:flex px-4 items-center">Options: {{ q.options }}</div>
        <div class="flex w-3/5 md:w/12">
          <div class="w-1/2 px-4">
            <button class="text-right text-green-600" @click="editQues(q)">
              {{ dialogs.editQues ? "Hide" : "Edit" }}
            </button>
          </div>
          <div class="w-1/2 px-4">
            <button class="text-right text-grey" @click="deleteQuestion(q)">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dialogs.addQues">
      <div
        class="
          min-h-screen
          bg-gray-100
          py-6
          flex flex-col
          justify-center
          sm:py-12
        "
      >
        <div class="relative py-3 sm:max-w-xl sm:mx-auto">
          <div
            class="
              relative
              px-4
              py-10
              bg-white
              mx-8
              md:mx-0
              shadow
              rounded-3xl
              sm:p-10
            "
          >
            <div class="max-w-md mx-auto">
              <div class="flex items-center space-x-5">
                <div
                  class="
                    h-14
                    w-14
                    bg-yellow-200
                    rounded-full
                    flex flex-shrink-0
                    justify-center
                    items-center
                    text-yellow-500 text-2xl
                    font-mono
                  "
                >
                  i
                </div>
                <div
                  class="
                    block
                    pl-2
                    font-semibold
                    text-xl
                    self-start
                    text-gray-700
                  "
                >
                  <h2 class="leading-relaxed">Create a Question</h2>
                  <p class="text-sm text-gray-500 font-normal leading-relaxed">
                    All fields are mandatory*
                  </p>
                </div>
              </div>
              <div class="divide-y divide-gray-200">
                <div
                  class="
                    py-8
                    text-base
                    leading-6
                    space-y-4
                    text-gray-700
                    sm:text-lg
                    sm:leading-7
                  "
                >
                  <div class="flex flex-col">
                    <label class="leading-loose">Question Title</label>
                    <input
                      type="text"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Quiz title"
                      v-model="quesData.title"
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="leading-loose">Question Topic</label>
                    <input
                      type="text"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Quiz title"
                      v-model="quesData.subject"
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="leading-loose">Option 1</label>
                    <input
                      type="text"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Category or topic"
                      v-model="quesData.opt1"
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="leading-loose">Option 2</label>
                    <input
                      type="text"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Category or topic"
                      v-model="quesData.opt2"
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="leading-loose">Option 3</label>
                    <input
                      type="text"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Category or topic"
                      v-model="quesData.opt3"
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="leading-loose">Option 4</label>
                    <input
                      type="text"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Category or topic"
                      v-model="quesData.opt4"
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="leading-loose">Correct Option (1-4)</label>
                    <input
                      type="number"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Category or topic"
                      v-model="quesData.correct"
                    />
                  </div>
                </div>
                <div class="pt-4 flex items-center space-x-4">
                  <button
                    class="
                      flex
                      justify-center
                      items-center
                      w-full
                      text-gray-900
                      px-4
                      py-3
                      rounded-md
                      focus:outline-none
                    "
                    @click="dialogs.addQues = false"
                  >
                    <svg
                      class="w-6 h-6 mr-3"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                    Cancel
                  </button>
                  <button
                    class="
                      bg-blue-500
                      flex
                      justify-center
                      items-center
                      w-full
                      text-white
                      px-4
                      py-3
                      rounded-md
                      focus:outline-none
                    "
                    @click="addQues"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dialogs.editQues">
      <div
        class="
          min-h-screen
          bg-gray-100
          py-6
          flex flex-col
          justify-center
          sm:py-12
        "
      >
        <div class="relative py-3 sm:max-w-xl sm:mx-auto">
          <div
            class="
              relative
              px-4
              py-10
              bg-white
              mx-8
              md:mx-0
              shadow
              rounded-3xl
              sm:p-10
            "
          >
            <div class="max-w-md mx-auto">
              <div class="flex items-center space-x-5">
                <div
                  class="
                    h-14
                    w-14
                    bg-yellow-200
                    rounded-full
                    flex flex-shrink-0
                    justify-center
                    items-center
                    text-yellow-500 text-2xl
                    font-mono
                  "
                >
                  i
                </div>
                <div
                  class="
                    block
                    pl-2
                    font-semibold
                    text-xl
                    self-start
                    text-gray-700
                  "
                >
                  <h2 class="leading-relaxed">Edit Question</h2>
                  <p class="text-sm text-gray-500 font-normal leading-relaxed">
                    All fields are mandatory*
                  </p>
                </div>
              </div>
              <div class="divide-y divide-gray-200">
                <div
                  class="
                    py-8
                    text-base
                    leading-6
                    space-y-4
                    text-gray-700
                    sm:text-lg
                    sm:leading-7
                  "
                >
                  <div class="flex flex-col">
                    <label class="leading-loose">Question Title</label>
                    <input
                      type="text"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Quiz title"
                      v-model="editQuesData.question_description"
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="leading-loose">Question Topic</label>
                    <input
                      type="text"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Quiz title"
                      v-model="editQuesData.question_subject"
                    />
                  </div>
                  <div
                    class="flex flex-col"
                    v-for="(o, i) in editQuesData.options"
                    :key="o"
                  >
                    <label class="leading-loose">Option {{ i + 1 }}</label>
                    <input
                      type="text"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Category or topic"
                      v-model="editQuesData.options[i]"
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="leading-loose">Correct Answer</label>
                    <input
                      type="text"
                      class="
                        px-4
                        py-2
                        border
                        focus:ring-gray-500
                        focus:border-gray-900
                        w-full
                        sm:text-sm
                        border-gray-300
                        rounded-md
                        focus:outline-none
                        text-gray-600
                      "
                      placeholder="Category or topic"
                      v-model="editQuesData.answer"
                    />
                  </div>
                </div>
                <div class="pt-4 flex items-center space-x-4">
                  <button
                    class="
                      flex
                      justify-center
                      items-center
                      w-full
                      text-gray-900
                      px-4
                      py-3
                      rounded-md
                      focus:outline-none
                    "
                    @click="dialogs.editQues = false"
                  >
                    <svg
                      class="w-6 h-6 mr-3"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                    Cancel
                  </button>
                  <button
                    class="
                      bg-blue-500
                      flex
                      justify-center
                      items-center
                      w-full
                      text-white
                      px-4
                      py-3
                      rounded-md
                      focus:outline-none
                    "
                    @click="updateQues"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orgID: "upskill",
      items: [],
      questions: [],
      selectedQuiz: {},
      quizData: {},
      quesData: {},
      editQuesData: {},
      qidSet: [],
      dialogs: {
        addQuiz: false,
        editQuiz: false,
        addQues: false,
        editQues: false,
      },
    };
  },
  methods: {
    deleteQuiz(q) {
      this.$upskill.orgDeleteQuizByID(q.quiz_id).then(() => {
        this.getQuizzes();
      });
    },
    deleteQuestion(q) {
      this.$upskill.orgDeleteQuesByID(q.question_id).then(() => {
        this.$upskill
          .orgGetQuesByQuizID(this.selectedQuiz.quiz_id)
          .then((res) => {
            this.questions = res.data.question_data;
            if (this.questions == null) {
              this.dialogs.addQuestion = true;
            }
          });
      });
    },
    getQuizzes() {
      this.$upskill.orgGetQuizzes("upskill").then((res) => {
        this.items = res.data.data;
      });
    },
    addQuiz() {
      let form = {
        org_id: "upskill",
        quiz_title: this.quizData.title,
        quiz_subject: this.quizData.subject,
        time_required: String(this.quizData.time) + "s",
        status: "active",
        Other: {},
      };
      this.$upskill.orgAddQuiz(form).then((res) => {
        console.log(res.data);
        this.dialogs.addQuiz = false;
        this.getQuizzes();
      });
    },
    editQuiz(q) {
      this.dialogs.editQuiz = !this.dialogs.editQuiz;
      this.dialogs.addQuiz = false;
      this.selectedQuiz = q;
      console.log(q);
      this.$upskill.orgGetQuesByQuizID(q.quiz_id).then((res) => {
        this.questions = res.data.question_data;
        if (this.questions == null) {
          this.dialogs.addQuestion = true;
        }
      });
    },
    updateQues() {
      this.$upskill.orgUpdateQues(this.editQuesData).then((res) => {
        this.dialogs.editQues = false;
        console.log(res);
      });
    },
    addQues() {
      console.log(this.quesData);
      let ques_id = -1;
      let form = {
        question_subject: this.quesData.subject,
        question_description: this.quesData.title,
        question_type: "MCQ",
        options: [
          this.quesData.opt1,
          this.quesData.opt2,
          this.quesData.opt3,
          this.quesData.opt4,
        ],
        answer_type: "predefined",
        answer: this.quesData[`opt${this.quesData.correct}`],
        score: 1,
      };
      this.$upskill.orgAddQues(form).then((res) => {
        ques_id = res.data.data.question_id;
        this.qidSet.push(ques_id);
        let form2 = {
          quiz_id: this.selectedQuiz.quiz_id,
          question_ids: [ques_id],
        };
        this.$upskill.orgSaveQuesToQuiz(form2).then((res2) => {
          console.log(res2);
        });
      });
    },
    editQues(q) {
      this.editQuesData = q;
      this.dialogs.editQues = !this.dialogs.editQues;
      this.dialogs.addQues = false;
    },
  },
  created() {
    this.getQuizzes();
  },
};
</script>
