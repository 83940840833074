<template>
  <div>
    <div v-if="!submitted">
      <div class="mt-6" v-if="got >= expected">
        <div>
          <div
            class="
              container
              mx-auto
              text-center
              p-4
              md:text-left
              md:flex-row
              md:items-center
              md:justify-between
              md:p-12
              bg-gray-200
              rounded-md
            "
          >
            <div class="text-2xl font-semibold">
              <p class="text-gray-900">
                Congratulations! You have been shortlisted for the post.
              </p>
              <p class="mt-3 mb-5">
                Please submit the following information so that we can assess
                your application.
              </p>
              <h4 class="top-gap-1 text-md text-lg">Your Name*</h4>
              <input
                type="text"
                required
                v-model="model.name"
                placeholder="State your full name"
                class="text-placeholder mt-5"
              />

              <h4 class="mt-5 text-lg">Your Email Address*</h4>
              <input
                type="email"
                required
                v-model="model.email"
                placeholder="Ex: selim@gmail.com"
                class="text-placeholder mt-5"
              />

              <h4 class="mt-5 text-md text-lg">Your Phone Number*</h4>
              <input
                type="text"
                required
                v-model="model.phone"
                placeholder="Ex: 017XXXXXXX"
                class="text-placeholder mt-5"
              />
              <h4 class="mt-5 text-lg">LinkedIn URL*</h4>
              <input
                type="text"
                required
                v-model="model.linkedin"
                placeholder="Ex: https://linkedin.com/in/hridoy"
                class="text-placeholder mt-5"
              />
              <div>
                <uploader @uploaded="uploaded"></uploader>
                <p v-if="fileDate.length > 2">
                  <span>File received at: {{ fileDate }}</span>
                </p>
              </div>
              <button
                class="
                  p-2
                  rounded
                  font-heading font-bold
                  bg-blueDs
                  text-white
                  mt-4
                  text-md
                  pa-4
                  shadow-UBlue
                  transition
                  duration-500
                  ease-in-out
                  hover:bg-UCDarkPink
                "
                style="min-width: 140px"
                @click="Submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 mb-16" v-else>
        <div>
          <div
            class="
              text-center
              flex flex-col
              p-4
              md:text-left
              md:flex-row
              md:items-center
              md:justify-between
              md:p-12
              bg-purple-100
              rounded-md
            "
          >
            <div class="text-2xl font-semibold">
              <div class="text-gray-900">
                <p>
                  Thank you for your interest in the position at Upskill.
                  Unfortunately, we will not be moving forward with your
                  application, but we appreciate your time and interest in
                  Upskill.
                </p>
                <p></p>
              </div>
            </div>

            <!--          <div class="mt-3 md:mt-0 md:ml-2">-->
            <!--            <div class="inline-block rounded-md text-lg font-semibold py-2 px-4 text-white bg-purple-500 cursor-pointer" @click="showPopup">-->
            <!--              Retry-->
            <!--            </div>-->
            <!--          </div>-->
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div
        class="
          text-center
          flex flex-col
          p-4
          md:text-left
          md:flex-row
          md:items-center
          md:justify-between
          md:p-12
          bg-purple-100
          rounded-md
        "
      >
        <div class="text-2xl font-semibold">
          <div class="text-gray-900">
            <p>
              Thank you for your submission! Please visit one of the following
              websites to learn more about us.
            </p>
            <ul class="pt-4">
              <li>
                <a href="https://upskill.com.bd" class="text-blue-600"
                  >Upskill</a
                >
              </li>
              <li>
                <a href="https://upskillclassroom.com" class="text-blue-600"
                  >Upskill Classroom</a
                >
              </li>
            </ul>
            <p></p>
          </div>
        </div>

        <!--          <div class="mt-3 md:mt-0 md:ml-2">-->
        <!--            <div class="inline-block rounded-md text-lg font-semibold py-2 px-4 text-white bg-purple-500 cursor-pointer" @click="showPopup">-->
        <!--              Retry-->
        <!--            </div>-->
        <!--          </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import uploader from './uploader'
import SA from 'sweetalert2'
export default {
  props: ['got', 'expected'],
  components: {
    uploader,
  },
  data() {
    return {
      model: { name: '', email: '', phone: '' },
      fileDate: '',
      submitted: false,
    }
  },
  methods: {
    uploaded(e) {
      // console.log(e)
      this.model.cv = e.link
      this.isFileUploaded = true
      this.fileDate = moment().format('LT')
    },
    Submit() {
      if (
        !this.model.cv ||
        !this.model.name ||
        !this.model.email ||
        !this.model.phone ||
        !this.model.linkedin
      ) {
        SA.fire('All fields are required!', '')
      } else {
        // const LINK = "http://localhost:8080";
        const LINK = 'https://service6.upskill.com.bd'
        this.model.score = String(localStorage.getItem('score_data'))
        this.model.session = localStorage.getItem('session_data')
        this.model.job = localStorage.getItem('job_data')
        axios
          .post(`${LINK}/api/submit/assessment`, this.model)
          .then(() => {
            SA.fire('Form submitted successfully! You will be contacted soon.')
            this.model = {}
            location.href = 'https://upskill.com.bd/'
          })
          .catch(() => {
            // console.log(err)
            SA.fire(
              'Please fill up all the fields! Please contact us if you any queries.'
            )
          })
      }
    },
  },
}
</script>

<style scoped>
input[type='text'],
input[type='email'] {
  font-size: 16px;
  padding: 10px;
  width: 100%;
  outline: 0;
  border: 1px solid #999;
  border-radius: 10px;
}
input[type='text']:focus,
input[type='email']:focus {
  box-shadow: 0 0 15px 1px #999;
  border: 1px solid #777;
}
input[type='search'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
</style>
