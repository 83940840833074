<template>
  <div class="login-page">
    <div class="form shadow-md rounded">
      <form class="login-form">
        <h2 class="font-bold mb-2">
          Please provide your details to start the quiz
        </h2>
        <input
          type="text"
          v-model="user.name"
          placeholder="username"
          class="rounded"
        />
        <input
          type="text"
          v-model="user.email"
          placeholder="email"
          class="rounded"
        />
        <btn
          @click.prevent="submitUserInfo()"
          class="
            bg-UCPink
            text-white
            mt-4
            shadow-UBlue
            w-full
            transition
            duration-500
            ease-in-out
            hover:bg-UCDarkPink
          "
          >Start Quiz</btn
        >
      </form>
      <!-- <p>{{ user }}</p> -->
    </div>
  </div>
</template>

<script>
import btn from "@/components/BaseButton.vue";
export default {
  name: "FormPage",
  components: {
    btn,
  },
  data() {
    return {
      user: {
        name: "",
        email: "",
      },
    };
  },
  computed: {
    fillAll() {
      return this.user.name != "" &&
        this.user.email != "" &&
        this.validateEmail(this.user.email)
        ? false
        : true;
    },
  },
  methods: {
    submitUserInfo() {
      if (
        this.user.name != "" &&
        this.user.email != "" &&
        this.validateEmail(this.user.email)
      ) {
        this.$store.state.form.username = this.user.name;
        this.$store.state.form.email = this.user.email;
        this.$router.push({
          name: "quiz",
          params: { id: this.$route.params.id },
        });
      } else if (
        !this.validateEmail(this.user.email) ||
        this.user.name == "" ||
        this.user.email == ""
      ) {
        this.ErrorAlert("Please put a valid username/email");
      }
    },
    submitFormInfo() {
      if (this.user.name != "") {
        this.$store.state.publicForm.username = this.user.name;
        this.$store.commit("showScore", true);
      } else if (this.user.name == "") {
        this.ErrorAlert("Please put a valid username");
      }
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>

<style scoped>
.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  height: 100vh;
}
.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  /* max-width: 360px; */
  margin: 0 auto 100px;
  padding: 45px;
  text-align: left;
}
.form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #ef3b3a;
}
.error-toast {
  background-color: #f27474 !important;
}
</style>
