<template>
  <div class="login-page">
    <div class="form shadow-md rounded">
      <form class="login-form">
        <div v-if="!isForgetPassword">
          <h2 class="font-bold text-lg mb-2">Log In to continue</h2>
          <input
            type="text"
            v-model="model.email"
            placeholder="email"
            class="rounded"
          />
          <input
            type="password"
            v-model="model.password"
            placeholder="password"
            class="rounded"
          />
          <btn
            @click.prevent="login()"
            class="
              bg-UCPink
              text-white
              mt-4
              shadow-UBlue
              w-full
              transition
              duration-500
              ease-in-out
              hover:bg-UCDarkPink
            "
            >Log in</btn
          >
          <div class="flex flex-start mt-2">
            <button
              class="cursor-pointer text-UCDarkPink"
              @click.prevent="isForgetPassword = true"
            >
              Forgot password?
            </button>
          </div>
        </div>
        <div v-else-if="isForgetPassword">
          <h2 class="font-bold text-lg mb-2">
            Please put the email you used for signup
          </h2>
          <input
            type="text"
            v-model="fp_email.email"
            placeholder="email"
            class="rounded"
          />
          <btn
            @click.prevent="fpSubmit()"
            class="
              bg-UCPink
              text-white
              mt-4
              shadow-UBlue
              w-full
              transition
              duration-500
              ease-in-out
              hover:bg-UCDarkPink
            "
            >reset password</btn
          >
        </div>
      </form>
      <!-- <p>{{ user }}</p> -->
    </div>
  </div>
</template>

<script>
import btn from "@/components/BaseButton.vue";
import Swal from "sweetalert2";
export default {
  name: "LogIn",
  components: {
    btn,
  },
  data() {
    return {
      model: {
        password: "",
        email: "",
      },
      isForgetPassword: false,
      fp_email: {
        email: "",
      },
    };
  },
  computed: {
    fillAll() {
      return this.model.name != "" &&
        this.model.email != "" &&
        this.validateEmail(this.model.email)
        ? false
        : true;
    },
  },
  methods: {
    submitUserInfo() {
      if (
        this.model.name != "" &&
        this.model.email != "" &&
        this.validateEmail(this.model.email)
      ) {
        //this.$store.state.form.username = this.user.name;
        //this.$store.state.form.email = this.user.email;
        // this.$router.push({
        //   name: "quiz",
        //   params: { id: this.$store.state.form.quiz_id },
        // });
        //this.$emit("quizStarted");
      } else if (
        !this.validateEmail(this.model.email) ||
        this.model.name == "" ||
        this.model.email == ""
      ) {
        var toastMixin = Swal.mixin({
          toast: true,
          icon: "error",
          title: "Please put correct email or username",
          animation: false,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        toastMixin.fire({
          title: "please put a valid email or username",
          icon: "error",
        });
      }
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    login() {
      this.$upskill
        .loginUser(this.model)
        .then((res) => {
          localStorage.removeItem("session");
          localStorage.setItem("session", res.data.token);
          //this.$router.push({ path: "user" });
          this.$router.go(-1);
        })
        .catch(() => {
          this.ErrorAlert("Please put correct email/password");
        });
    },
    fpSubmit() {
      this.$upskill
        .pwInit(this.fp_email.email)
        .then(() => {
          this.SuccessAlert(
            "An email has been sent your inbox! Please check for further instructions."
          );
          this.isForgetPassword = false;
        })
        .catch(() => {
          this.btnLoading = false;
          this.SuccessAlert(
            "An email has been sent your inbox! Please check for further instructions."
          );
          this.isForgetPassword = false;
        });
    },
  },
};
</script>

<style scoped>
.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  height: 100vh;
}
.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  /* max-width: 360px; */
  margin: 0 auto 100px;
  padding: 45px;
}
.form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #ef3b3a;
}
.error-toast {
  background-color: #f27474 !important;
}
</style>
