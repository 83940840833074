<template>
  <div class="container mx-auto">
    <div class="bg-blueDs p-4 rounded">
      <div v-show="!bangla" class="text-white font-bold text-lg">
        <i class="fas fa-medal"></i>
        Score
      </div>
      <div
        v-if="score > 0"
        class="text-UCYellow-light font-bold text-6xl font-heading"
      >
        {{ percentage }}%
      </div>
      <div v-show="!bangla" class="text-lg text-white">
        You have received a score of {{ score }} out of {{ totalScore }}!
      </div>
    </div>
    <result
      :got="parseInt(score)"
      :expected="parseInt(0.6 * totalScore)"
      :key="score"
    />
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Result from '../components/Assessment/result'
export default {
  // props: ["num", "total"],
  data() {
    return {}
  },
  components: {
    Result,
  },
  computed: {
    percentage() {
      let p = (this.score / this.totalScore) * 100
      return Math.trunc(p)
    },
    showReview() {
      return this.$store.state.showReview
    },
    score() {
      return this.$store.state.userTotalScore
    },
    totalScore() {
      return this.$store.state.totalScore
    },
    bangla() {
      return this.$store.state.bn
    },
    publicForm() {
      return this.$store.state.publicForm
    },
    isPublic() {
      return this.$store.state.isPublic
    },
    getQuizId() {
      return this.$store.state.pubStartForm.quiz_id
    },
  },
  methods: {
    handleReview() {
      this.$store.commit('showReview', true)
    },
    showPopup() {
      if (this.bangla && this.isPublic) {
        Swal.fire({
          title: 'আপনার উত্তর রিভিউ করতে ইউজারনেইম এবং ইমেইল টাইপ করুন',
          html: `<input type="text" id="name" class="swal2-input" placeholder="ইউজারনেইম">
          <input type="email" id="email" class="swal2-input" placeholder="ইমেইল">`,
          confirmButtonText: 'জমা দিন',
          focusConfirm: false,
          preConfirm: () => {
            const name = Swal.getPopup().querySelector('#name').value
            const email = Swal.getPopup().querySelector('#email').value
            if (!name || !email) {
              Swal.showValidationMessage(`Please enter your username`)
            }
            return { name: name, email: email }
          },
        }).then((result) => {
          //console.log(result.value.login);
          this.publicForm.username = result.value.name
          this.publicForm.email = result.value.email
          this.$upskill.pubSubmitDetails(this.publicForm).then(() => {
            //this.Goto({ name: "PublicResults" });

            this.handleReview()
            this.$store.dispatch('getLeaderBoard', this.getQuizId)
          })
        })
      } else if (!this.bangla && this.isPublic) {
        Swal.fire({
          title: 'Please put your email to review your answers',
          html: `<input type="text" id="name" class="swal2-input" placeholder="Name">
           <input type="email" id="email" class="swal2-input" placeholder="email">,
           <input type="tel" id="phone" class="swal2-input" placeholder="phone number">`,
          confirmButtonText: 'Submit',
          focusConfirm: false,
          preConfirm: () => {
            const name = Swal.getPopup().querySelector('#name').value
            const email = Swal.getPopup().querySelector('#email').value
            const phone = Swal.getPopup().querySelector('#phone').value
            if (!name || !email || !phone) {
              Swal.showValidationMessage(`Please enter all required fields`)
            }
            return { name: name, email: email, phone: phone }
          },
        }).then((result) => {
          this.publicForm.username = result.value.name
          this.publicForm.email = result.value.email
          this.publicForm.phone = result.value.phone
          this.$upskill.pubSubmitDetails(this.publicForm).then(() => {
            //this.Goto({ name: "PublicResults" });

            this.handleReview()

            this.$store.dispatch('getLeaderBoard', this.getQuizId)
          })
        })
      } else {
        this.handleReview()
      }
    },
  },
}
</script>

<style></style>
