<template>
  <div>
    <CHeader v-show="!bangla" />
    <CHeaderBn v-show="bangla" />
    <div class="flex flex-col-reverse sm:grid grid-cols-2 p-1">
      <div class="mt-4 sm:mt-0 w-full place-self-start">
        <LeaderBoard />
      </div>
      <div class="w-full sm:w-3/4 2xl:w-1/2 place-self-start mt-12">
        <Results />
      </div>
    </div>
    <Footer v-show="!bangla" class="fix" />
    <FooterBn v-show="bangla" class="fix" />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import FooterBn from "@/components/FooterBn.vue";
import CHeader from "@/components/CHeader.vue";
import CHeaderBn from "@/components/CHeaderBn.vue";
import LeaderBoard from "@/components/LeaderBoard.vue";
import Results from "@/components/Results.vue";
export default {
  name: "PublicResults",
  components: {
    Footer,
    FooterBn,
    CHeaderBn,
    CHeader,
    Results,
    LeaderBoard,
  },
  data() {
    return {
      // lBoard: "",
      // total: "",
    };
  },
  computed: {
    getQuizId() {
      return this.$store.state.pubStartForm.quiz_id;
    },
    bangla() {
      return this.$store.state.bn;
    },
  },
  //created() {
  // this.$upskill.getLeaderBoard(this.getQuizId).then((res) => {
  //   this.lBoard = res.data.data;
  //   this.total = res.data.quiz_score;
  // });
  // this.$store.dispatch("getLeaderBoard", this.getQuizId);
  //},
};
</script>

<style></style>
